export const SHOPPING_LISTS_CREATE_FULFILLED =
  'waitrose/shopping-list/SHOPPING_LISTS_CREATE_FULFILLED' as const;
export const SHOPPING_LISTS_CREATE_PENDING =
  'waitrose/shopping-list/SHOPPING_LISTS_CREATE_PENDING' as const;
export const SHOPPING_LISTS_CREATE_REJECTED =
  'waitrose/shopping-list/SHOPPING_LISTS_CREATE_REJECTED' as const;
export const SHOPPING_LISTS_FULFILLED = 'waitrose/shopping-list/SHOPPING_LISTS_FULFILLED' as const;
export const SHOPPING_LISTS_HIDE_MODAL =
  'waitrose/shopping-lists/SHOPPING_LISTS_HIDE_MODAL' as const;
export const SHOPPING_LISTS_PENDING = 'waitrose/shopping-list/SHOPPING_LISTS_PENDING' as const;
export const SHOPPING_LISTS_REJECTED = 'waitrose/shopping-list/SHOPPING_LISTS_REJECTED' as const;
export const SHOPPING_LISTS_SHOW_MODAL =
  'waitrose/shopping-lists/SHOPPING_LISTS_SHOW_MODAL' as const;
export const SHOPPING_LISTS_ACTIONS = [
  SHOPPING_LISTS_PENDING,
  SHOPPING_LISTS_FULFILLED,
  SHOPPING_LISTS_REJECTED,
] as const;
export const SHOPPING_LISTS_CREATE_ACTIONS = [
  SHOPPING_LISTS_CREATE_PENDING,
  SHOPPING_LISTS_CREATE_FULFILLED,
  SHOPPING_LISTS_CREATE_REJECTED,
] as const;
