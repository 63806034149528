import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import { CircleAdd } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { Alert } from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import { Recipe, RecipeSummaryItem } from 'api/definitions/recipes/index.d';
import { Filter } from 'services/recipes-landing-service';
import { Spinner } from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';
import { RecipeCard } from '../Content/RecipeCard';
import PreferencesModal from './Preferences';
import styles from './RecipeSelection.scss';
import { FilterTitle, FilterTitles, getFiltersFromSearch, SearchFilter } from './Filters';

type MealPlanRecipesProps = {
  recipes: RecipeSummaryItem[];
  filters: Filter[];
  loading: boolean;
  moreRecipesAvailable: boolean;
  onFilterChange: (filters: SearchFilter[]) => void;
  onSelect: (recipe: Recipe['id']) => void;
  onView: (recipe: Recipe['id']) => void;
  loadMore: (searchFilters: SearchFilter[]) => void;
};

export const RecipeSelection = ({
  recipes,
  filters,
  loading,
  moreRecipesAvailable,
  onFilterChange,
  onSelect,
  onView,
  loadMore,
}: MealPlanRecipesProps) => {
  const location = useLocation();

  const [showPrefs, setShowPrefs] = useState<boolean>(false);
  const [searchFiltersState, setSearchFiltersState] = useState<SearchFilter[]>([]);
  const activeFilters = searchFiltersState.length
    ? searchFiltersState
    : getFiltersFromSearch(new URLSearchParams(location.search));
  const count = activeFilters
    .filter(f => FilterTitles.includes(f.title as FilterTitle))
    .reduce<number>((acc, f) => acc + f.values.length, 0);

  const [prevSearchFilters, setPrevSearchFilters] = useState<SearchFilter[]>(activeFilters);
  const [prevFilters, setPrevFilters] = useState<Filter[]>(filters);
  const loadingMoreRef = useRef(false);

  useEffect(() => {
    if (!loading) {
      loadingMoreRef.current = false;
    }
  }, [loading]);

  if (loading && !showPrefs && !loadingMoreRef.current) {
    return <Spinner className={styles.loading} isActive />;
  }

  return (
    <>
      <div className={styles.header}>
        <button type="button" className={styles['prefs-button']} onClick={() => setShowPrefs(true)}>
          Edit preferences{count ? <span className={styles.badge}>{count}</span> : ''}
        </button>
      </div>
      <section className={styles.section} aria-label="Available recipes">
        <h3 className="sr-only">Recipes</h3>
        <div className={styles.scrollable}>
          {!loading && !recipes.length && (
            <Alert
              type="warning"
              title="No matches found"
              message="Your search provided no results. Try editing your preferences for more results."
            />
          )}
          <ul className={styles.list}>
            {recipes.map(recipe => (
              <RecipeCard
                key={recipe.id}
                recipe={recipe}
                headingLevel="h4"
                onClick={e => {
                  onView(recipe.id);
                  e.preventDefault();
                }}
                analytics={{}}
                className={styles.card}
              >
                <Button
                  theme="primary"
                  width="full"
                  className={styles['add-recipe']}
                  onClick={() => onSelect(recipe.id)}
                  startIcon={<CircleAdd />}
                >
                  Add recipe
                </Button>
              </RecipeCard>
            ))}
          </ul>
          {moreRecipesAvailable && (
            <Button
              theme="secondary"
              className={styles['load-more']}
              onClick={() => {
                loadingMoreRef.current = true;
                loadMore(searchFiltersState);
              }}
            >
              Load more
            </Button>
          )}
        </div>
      </section>
      <PreferencesModal
        isOpen={showPrefs}
        onClose={(action: 'close' | 'apply') => {
          if (action === 'close') {
            onFilterChange(prevSearchFilters);
            setSearchFiltersState(prevSearchFilters);
          } else if (action === 'apply') {
            setPrevSearchFilters(searchFiltersState);
          }
          setPrevFilters(filters);
          setShowPrefs(false);
        }}
        filters={prevFilters.length ? prevFilters : filters}
        activeFilters={prevSearchFilters.length ? prevSearchFilters : activeFilters}
        onFilterChange={searchFilters => {
          setSearchFiltersState(searchFilters);
          onFilterChange(searchFilters);
        }}
      />
    </>
  );
};
