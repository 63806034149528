import { type BannerResponse } from 'api/definitions/adtech/types';
import createReducer from 'redux/createReducer';
import { ADTECH_BANNERS } from '../actions/types';

import initialState from './initial-state';

export type BannersByCategory = BannerResponse['bannerAds'];

export type BannersState = {
  byCategory: BannersByCategory;
};

type BannersSuccessAction = {
  result: BannerResponse;
};

const bannerSuccess = (state: BannersState, action: BannersSuccessAction) => {
  return {
    ...state,
    byCategory: {
      ...state.byCategory,
      ...action.result.bannerAds,
    },
  };
};

const actionTypeReducerMap = [[ADTECH_BANNERS.success, bannerSuccess]] as const;

export default createReducer(initialState, actionTypeReducerMap);
