import React, { MouseEvent } from 'react';
import { categoryNameToUrl } from 'utils/format';

import { Category, getCategoryById } from 'redux/modules/taxonomy/selectors/get-category-by-id';
import { getMegaMenuMaxLength } from 'redux/modules/page/selectors/mega-menu';

import MenuLink from 'components/MegaMenu/MenuLink';
import { useWtrSelector } from 'redux/hooks';

interface Props {
  level: number;
  path: string;
  isActive: boolean;
  onClick: (event: MouseEvent, categoryId: string | undefined, url: string) => void;
  categoryId: string;
}

const SubCategory = ({ categoryId, isActive, onClick, path, level }: Props) => {
  const category = useWtrSelector(state => getCategoryById(state, categoryId)) as Category;
  const maxLength = useWtrSelector(getMegaMenuMaxLength);

  const { name: title, emptyOfProducts, hiddenInNav } = category;
  const shortName = category.shortName || categoryNameToUrl(title);
  const additionalClasses = category.additionalClasses || '';
  const hasDescendants = category.hasDescendants && level + 1 < maxLength;
  const url = category.url || `${path}/${shortName}`;

  return (
    <MenuLink
      additionalClasses={additionalClasses}
      categoryId={categoryId}
      emptyOfProducts={emptyOfProducts}
      hasDescendants={hasDescendants}
      hiddenInNav={hiddenInNav}
      isActive={isActive}
      onClick={onClick}
      shortName={shortName}
      title={title}
      url={url}
    />
  );
};

export default SubCategory;
