import { apiCallback } from 'api';
import { ApiCallArgs } from 'api/types';
import { ApiError } from 'constants/errors';
import { Product, CategoryLevelFilter } from 'constants/products';
import { trackPersonalisationHeaderAndRecommendations } from 'api/definitions/favourites-experience/personalisation';
import type { ExperienceFragmentLocationData } from 'redux/modules/experience-fragments/actions/types';

type SearchTag = {
  text: string;
  value: string;
  group: string;
  id: string;
  count: number;
};

type Filters = {
  group: string;
  filters: {
    applied: boolean;
    count: number;
    group: string;
    id: string;
    text: string;
    value: string;
  }[];
};

type Criteria = {
  searchTags: SearchTag[];
  filters: Filters[];
  sortBy: string;
  alternative: boolean;
  suggestedSearchTags?: [];
};

type Metadata = {
  size: number;
  sortBy: string;
  start: number;
  pageTotalResults: number;
  totalResults: number;
  hasNextPage: boolean;
};

type BannerAd = {
  id: string;
  name: string;
  type: string;
  position: number;
  marketingBadge?: string;
  image: {
    src: string;
    altText: string;
    mode: string;
  };
  textArea: {
    heading: string;
    color: string;
    subHeading: string;
    offerHeading: string;
    link: {
      text: string;
      url: string;
      altText: string;
    }[];
  };
  products: {
    lineNumber: string;
    id: string;
    name: string;
  }[];
};

export type FavouritesExperienceCategory = {
  id: string;
  name: string;
  categoryTotalResults: number;
  orderedComponentsAndProducts: {
    type: string;
    id: string;
    field: string;
  }[];
  bannerAds: BannerAd[];
};

export type FavouritesExperienceResponse = {
  categories: FavouritesExperienceCategory[];
  componentsAndProducts: Product[];
  content?: {
    favourites: ExperienceFragmentLocationData;
  };
  criteria: Criteria;
  filterCategories: CategoryLevelFilter[];
  ignoreCategories: string[];
  metadata: Metadata;
  recommendations: Product[];
};

const INCLUDES_BOUGHT_ONLINE = 'bought-online' as const;
const INCLUDES_BOUGHT_IN_BRANCH = 'bought-in-branch' as const;
const INCLUDES_USER_SELECTED = 'user-selected' as const;

export type FavouritesExperienceIncludes =
  | typeof INCLUDES_BOUGHT_ONLINE
  | typeof INCLUDES_BOUGHT_IN_BRANCH
  | typeof INCLUDES_USER_SELECTED;

const SORT_CATEGORY = 'CATEGORY' as const;
const SORT_PURCHASE_FREQUENCY = 'PURCHASE_FREQUENCY' as const;

export type FavouritesExperienceSort = typeof SORT_CATEGORY | typeof SORT_PURCHASE_FREQUENCY;

const favouritesDefinition = {
  service: 'favourites-experience',
  endpoint: 'favourites/${customerOrderId}',
  verbs: ['get'],
  defaultVersion: 1,
  headers: {
    experience: 'my-favourites-chosen-for-you',
  },
  query: {
    ignoreCategories: '${ignoreCategories}',
    promotionflag: '${onOffer}',
    sortBy: '${sortBy}',
    size: '48',
    start: '${start}',
    categoryIds: '${categoryId}',
    includes: '${includes}',
  },
};

const getFavouritesCallback = apiCallback(
  favouritesDefinition,
  'get',
  trackPersonalisationHeaderAndRecommendations,
);

const getFavourites = (
  args: {
    onOffer: 1 | null;
    sortBy: FavouritesExperienceSort;
    ignoreCategories?: string;
    start: number;
    includes: string;
    categoryId?: string | undefined;
  } & ApiCallArgs<never, { 'monetate-id'?: string }>,
): WtrApiCall<Promise<FavouritesExperienceResponse | ApiError>> => getFavouritesCallback(args);

const favouritesStarterBasketDefinition = {
  service: 'favourites-experience',
  endpoint: 'favourites/${customerOrderId}',
  verbs: ['get'],
  defaultVersion: 1,
  query: {
    onlyAvailableProducts: 'true',
    sortBy: SORT_PURCHASE_FREQUENCY,
    size: '6',
    start: '0',
    includes: '${includes}',
    categoryIds: '${categoryIds}',
  },
};

const getFavouritesStarterBasketCallback = apiCallback(favouritesStarterBasketDefinition, 'get');

const getFavouritesStarterBasket = (
  args: {
    includes: string;
  } & ApiCallArgs,
): WtrApiCall<Promise<FavouritesExperienceResponse | ApiError>> =>
  getFavouritesStarterBasketCallback(args);

export default {
  INCLUDES_BOUGHT_ONLINE,
  INCLUDES_BOUGHT_IN_BRANCH,
  INCLUDES_USER_SELECTED,
  SORT_CATEGORY,
  SORT_PURCHASE_FREQUENCY,
  get: getFavourites,
  getStarterBasket: getFavouritesStarterBasket,
};
