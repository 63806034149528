import React from 'react';

import { AEM_CONTENT_IMAGE_QUALITY } from 'constants/monetateExperiments';
import { useDecisionById } from 'components/Experiment/useDecisionById';
import { Image as WaitroseImage } from '@johnlewispartnership/wtr-content-component-library';
import type { CmsImageProps } from '@johnlewispartnership/wtr-content-component-library';

const Image = (props: CmsImageProps) => {
  const { variant, payload } = useDecisionById<object>(AEM_CONTENT_IMAGE_QUALITY.experiment);

  if (variant !== 'default') {
    const imageProps = {
      ...props,
      ...payload,
    };

    return <WaitroseImage {...imageProps} />;
  }

  return <WaitroseImage {...props} />;
};

export default Image;
