import React, { Fragment } from 'react';
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { SIGN_OUT } from 'constants/categoryIds';

import { useWtrDispatch, useWtrSelector } from 'redux/hooks';
import cmsExperienceFragment from 'redux/fetchers/experience-fragment';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import { bannerFetcher } from 'redux/fetchers/adtech';
import { selectBanners } from 'redux/modules/banners/selectors';
import { getFeatureFlags } from 'utils/feature-flags';
import SignOut from 'components/AuthenticationAction/SignOut';
import { ChevronRight, Warning } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { LazyIcon } from 'components/LazyIcon/LazyIcon';

import styles from './MenuLink.scss';
import { useMenuItemNavigation } from '../hooks/use-menu-item-navigation';

const MenuLink = ({
  bold,
  hasDescendants,
  display,
  icon,
  id,
  children,
  level,
  menus,
  name,
  offer,
  onClick,
  path,
  banner,
  showWarningIcon,
  toggleMobileMenu,
  renderAsAnchor,
}) => {
  const dispatch = useWtrDispatch();
  const banners = useWtrSelector(selectBanners);
  const isSignOut = id === SIGN_OUT;
  const isLink = path && !hasDescendants;
  const { handleClickItem, handleKeyDownItem, ref, tabIndex } = useMenuItemNavigation({
    display,
    hasDescendants,
    id,
    isLink,
    level,
    menus,
    name,
    onClick,
    skip: !display || isSignOut,
    toggleMobileMenu,
  });

  if (!display) return null;
  const testName = `${name ? `${name}-` : ''}menu-link-${id}`;
  const classes = classNames({
    [styles.bold]: bold,
    [styles.offer]: offer,
    [styles.showWarningIcon]: showWarningIcon,
  });
  const contents = (
    <Fragment>
      <div className={styles.nameWrapper}>
        {icon && <LazyIcon name={icon} className={styles.icon} size="small" />}
        {children && <Fragment>{children}</Fragment>}
        {!children && name && <span className={styles.name}>{name}</span>}
        {showWarningIcon && <Warning className={styles.warningIcon} data-testid="warning-icon" />}
      </div>
      {hasDescendants && <ChevronRight className={styles.descendantsIcon} size="small" />}
    </Fragment>
  );

  if (isSignOut) {
    return (
      <SignOut className={classNames(styles.link, classes)} data-testid={testName} innerRef={ref} />
    );
  }

  if (isLink) {
    return (
      <TextLink
        underline="hover"
        component={renderAsAnchor ? 'a' : Link}
        className={classNames(styles.link, classes)}
        data-actiontype="redirect"
        data-origincomponent="mobile megamenu link"
        data-shortdescription={name || children}
        ref={ref}
        onClick={handleClickItem}
        onKeyDown={handleKeyDownItem}
        {...(renderAsAnchor ? { href: path } : { to: path })}
        data-testid={testName}
      >
        {contents}
      </TextLink>
    );
  }
  return (
    <button
      className={classNames(styles.button, classes)}
      onClick={event => {
        handleClickItem(event);
        if (hasDescendants) {
          if (banner && !banners[path.substring(1)]?.length) {
            dispatch(cmsExperienceFragment(banner)());
          }
          if (getFeatureFlags().adtechMenuBanners && level === 0) {
            dispatch(bannerFetcher(id, path));
          }
        }
      }}
      onKeyDown={handleKeyDownItem}
      ref={ref}
      tabIndex={tabIndex}
      type="button"
      data-testid={testName}
    >
      {contents}
    </button>
  );
};

MenuLink.displayName = 'MenuLink';

MenuLink.propTypes = {
  bold: bool,
  display: bool,
  hasDescendants: bool,
  icon: string,
  id: string,
  children: node,
  toggleMobileMenu: func.isRequired,
  level: number,
  menus: arrayOf(
    shape({
      id: string,
    }),
  ),
  name: string,
  offer: bool,
  onClick: func,
  path: oneOfType([string, shape({ hash: string, pathname: string, search: string })]),
  banner: string,
  showWarningIcon: bool,
  renderAsAnchor: bool,
};

MenuLink.defaultProps = {
  bold: false,
  display: false,
  hasDescendants: false,
  icon: undefined,
  id: undefined,
  children: null,
  level: undefined,
  menus: [],
  name: undefined,
  offer: false,
  onClick: null,
  path: undefined,
  banner: undefined,
  showWarningIcon: false,
  renderAsAnchor: false,
};

export default MenuLink;
