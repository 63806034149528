import React, { useCallback, useRef, MouseEvent } from 'react';
import classNames from 'classnames';
import AnchorLink from 'components/AnchorLink';
import { useNavigation } from 'components/MegaMenu/NavigationProvider';
import { ChevronRight } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './MenuLink.scss';

import { urlParser, IURLSearch } from '../utils/urlParser';

export type MenuLinkProps = {
  additionalClasses?: string;
  categoryId?: string;
  emptyOfProducts?: boolean;
  hasDescendants?: boolean;
  hiddenInNav?: boolean;
  isActive?: boolean;
  label?: string;
  onClick: (event: MouseEvent, categoryId: string | undefined, url: string) => void;
  shortName: string;
  title: string | undefined;
  url: string | IURLSearch;
};

const MenuLink = ({
  additionalClasses,
  categoryId,
  emptyOfProducts,
  hasDescendants,
  hiddenInNav = false,
  isActive,
  label,
  onClick,
  shortName,
  title,
  url,
}: MenuLinkProps) => {
  const ref = useRef();
  const { handleClick, handleKeyDown, tabIndex } = useNavigation(ref, {
    id: categoryId,
    skip: null,
  });

  const handleClickMenuLink = useCallback(
    (event: MouseEvent) => {
      handleClick();
      if (onClick) onClick(event, categoryId, urlParser(url));
    },
    [handleClick, onClick, categoryId, url],
  );

  if (emptyOfProducts || hiddenInNav) return null;

  const linkProps = {
    'aria-expanded': hasDescendants ? isActive : 'undefined',
    'aria-haspopup': hasDescendants,
    'data-actiontype': hasDescendants ? undefined : 'redirect',
    'data-origincomponent': hasDescendants ? undefined : 'megamenu link',
    'data-shortdescription': hasDescendants ? undefined : title,
    className: classNames(
      styles.mLnk,
      { [styles.active]: isActive && hasDescendants },
      { [styles.more]: hasDescendants },
      styles[shortName],
      additionalClasses,
    ),
    'data-testid': `mm-${shortName}`,
    innerRef: ref,
    onClick: handleClickMenuLink,
    onKeyDown: handleKeyDown,
    tabIndex,
    title,
    to: urlParser(url),
  };

  if (title) {
    const text = label ? undefined : title;
    const textProps = {
      className: styles.text,
      ...(label && { dangerouslySetInnerHTML: { __html: label } }),
      title: text,
      'data-testid': 'menu-link-text',
    };

    return (
      <AnchorLink {...linkProps}>
        <span {...textProps}>{text}</span>
        {hasDescendants && <ChevronRight className={styles.forwardIcon} size="xsmall" />}
      </AnchorLink>
    );
  }

  return <span className={styles.loading}>loading...</span>;
};

export default MenuLink;
