import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';
import { ModalCloseEvent } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal/Modal';
import { dataLayer } from 'analytics/data-layer';
import { Filter } from 'services/recipes-landing-service';
import Filters, { FilterTitles, getFiltersFromSearch } from './Filters';
import type { SearchFilter } from './Filters';
import mealPlannerStyles from './MealPlannerGrid/index.scss';
import styles from './Preferences.scss';

interface PreferencesModal {
  isOpen: boolean;
  onClose: (action: 'close' | 'apply') => void;
  filters: Filter[];
  onFilterChange: (filters: SearchFilter[]) => void;
  activeFilters?: SearchFilter[];
}

type CheckedState = boolean[][];

const PreferencesModal = ({
  isOpen,
  onClose,
  filters,
  onFilterChange,
  activeFilters = [],
}: PreferencesModal) => {
  const location = useLocation();
  const [filtersCheckedState, setFiltersCheckedState] = React.useState<CheckedState>(
    Array.from({ length: filters.length }, (_, i) =>
      new Array(filters[i].values.length).fill(false),
    ),
  );

  useEffect(() => {
    const filtersToUse =
      activeFilters.length > 0
        ? activeFilters
        : getFiltersFromSearch(new URLSearchParams(location.search));

    const activeFilterMap = filtersToUse.reduce<Record<string, string[]>>((acc, v) => {
      acc[v.title] = v.values;
      return acc;
    }, {});

    const initialChecked = filters.reduce<CheckedState>(
      (acc, filter, i) => {
        acc[i] = new Array(filter.values.length).fill(false);
        if (activeFilterMap[filter.title]) {
          filter.values.forEach((v, j) => {
            if (activeFilterMap[filter.title].includes(v.name)) {
              acc[i][j] = true;
            }
          });
        }
        return acc;
      },
      Array.from({ length: filters.length }, () => []),
    );

    setFiltersCheckedState(initialChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, isOpen]);

  const onChange = (searchFilters: SearchFilter[]) => {
    onFilterChange(searchFilters);

    const filterSelections = FilterTitles.reduce<Record<string, string>>(
      (acc, title) => {
        const filterGroup = searchFilters.find(f => f.title === title);
        acc[title.replace(/ /g, '_').toLowerCase()] = filterGroup
          ? filterGroup.values.join('|')
          : '';
        return acc;
      },
      { event: 'meal_planner_filter' },
    );

    dataLayer.push(filterSelections);
  };

  const handleModalClose = (event?: ModalCloseEvent) => {
    if (event?.value === 'close') {
      onClose('close');
    }
  };

  return (
    <Modal
      titleText="Filters"
      isOpen={isOpen}
      handleModalClose={handleModalClose}
      className={mealPlannerStyles.modal}
      contentClassName={styles.content}
      buttons={[
        /*
        {
          buttonText: 'Clear all',
          theme: 'secondary',
          onClick: () => {
            navigate({ pathname: location.pathname, search: '' }, { replace: true });
            onChange(new URLSearchParams(''));
          },
        },
        */
        { buttonText: 'Apply filters', onClick: () => onClose('apply') },
      ]}
    >
      <Filters
        filters={filters}
        onChange={onChange}
        filtersCheckedState={filtersCheckedState}
        setFiltersCheckedState={setFiltersCheckedState}
      />
    </Modal>
  );
};

export default PreferencesModal;
