import { categoryNameToUrl } from 'utils/format';
import { WtrRootState } from 'redux/create-store';
import { getBanner } from 'redux/modules/banners/actions/banner';
import { selectBanners } from 'redux/modules/banners/selectors';
import {
  type Category,
  getMenuCategoryById,
} from 'redux/modules/taxonomy/selectors/get-menu-category-by-id';

export const bannerFetcher =
  (categoryId: string, path: string) => (dispatch: WtrDispatch, getState: WtrGetState) => {
    const childCategories: Record<string, Category> = getMenuCategoryById(
      getState() as WtrRootState,
      categoryId,
    ).categories;
    const existing = selectBanners(getState() as WtrRootState);
    const categoriesToFetch = Object.keys(childCategories)
      .filter(category => childCategories[category].name)
      .map(category =>
        [path.slice(1), categoryNameToUrl(childCategories[category].name.toLowerCase())].join('/'),
      )
      .filter(category => !existing[category]);

    return categoriesToFetch.length ? dispatch(getBanner(categoriesToFetch)) : Promise.resolve();
  };
