import { apiCallback } from 'api';

export default {
  post: apiCallback(
    {
      service: 'adtech',
      endpoint: 'adtech/banners',
      verbs: ['post'],
      defaultVersion: 2,
    },
    'post',
  ),
};
