import { MONETATE_DEFAULT_VARIANT } from './monetate';

export interface MonetateVariant extends Record<string, string> {
  default: typeof MONETATE_DEFAULT_VARIANT;
}

export interface MonetateExperiment {
  experiment: string;
  variant: MonetateVariant;
}

export const URGENCY_BANNER = {
  experiment: 'urgencyBanner',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    danger: 'danger',
    warning: 'warning',
    info: 'info',
  },
} as const satisfies MonetateExperiment;

export const NAV_LINKS_TRENDING = {
  experiment: 'navLinksTrending',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showTrending: 'showTrending',
  },
} as const satisfies MonetateExperiment;

export const POD_SLIDEDOWN_ADVERT_RECIPES = {
  experiment: 'podSlidedownAdvertRecipes',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showAdvertImage: 'showAdvertImage',
    showAdvertIcon: 'showAdvertIcon',
  },
  // provides following payload:
  //
  // {
  //    productIds: [],
  //    imageUrl?: "",
  //    iconName?: "",
  //    text: "",
  //    url: "",
  // }
} as const satisfies MonetateExperiment;

export const POD_SLIDEDOWN_ADVERT_RELATED = {
  experiment: 'podSlidedownAdvertRelated',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showAdvertImage: 'showAdvertImage',
    showAdvertLogo: 'showAdvertLogo',
  },
  // provides following payload:
  //
  // {
  //    productIds: [],
  //    imageUrl?: "",
  //    logoUrl?: "",
  //    text: "",
  //    url: "",
  // }
} as const satisfies MonetateExperiment;

export const POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH = {
  experiment: 'podSlidedownAdvertGoesWellWith',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showAdvertImage: 'showAdvertImage',
    showAdvertIcon: 'showAdvertIcon',
  },
  // provides following payload:
  //
  // {
  //    productIds: [],
  //    imageUrl?: "",
  //    iconName?: "",
  //    text: "",
  //    url: "",
  // }
} as const satisfies MonetateExperiment;

export const POD_SLIDEDOWN_MULTIBUY_NUDGE = {
  experiment: 'podSlidedownMultibuyNudge',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showLinkBuild: 'showLinkBuild',
    showLinkComplete: 'showLinkComplete',
    showLinkExplore: 'showLinkExplore',
  },
} as const satisfies MonetateExperiment;

export const LOYALTY_SHOW_MIGRATION_COMPETITIONS_PAGE = {
  experiment: 'loyalty_replaceMyWaitroseCompetitions',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    loyalty_replaceMyWaitroseCompetitions: 'loyalty_replaceMyWaitroseCompetitions',
  },
} as const satisfies MonetateExperiment;

export const LOYALTY_SHOW_MIGRATION_BENEFITS_PAGE = {
  experiment: 'loyalty_replaceMyWaitroseBenefits',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    loyalty_replaceMyWaitroseBenefits: 'loyalty_replaceMyWaitroseBenefits',
  },
} as const satisfies MonetateExperiment;

export const LOYALTY_SHOW_MIGRATION_CARDS_AND_DETAILS_PAGE = {
  experiment: 'loyalty_replaceMyWaitroseCardAndDetails',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    loyalty_replaceMyWaitroseCardAndDetails: 'loyalty_replaceMyWaitroseCardAndDetails',
  },
} as const satisfies MonetateExperiment;

export const LOYALTY_REDIRECT_HUB_TO_VOUCHERS_PAGE = {
  experiment: 'loyalty_redirectHubToVouchersPage',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    loyalty_redirectHubToVouchersPage: 'loyalty_redirectHubToVouchersPage',
  },
} as const satisfies MonetateExperiment;

export const SHOW_PRODUCT_TAGS = {
  experiment: 'productTags',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showTags: 'showTags',
    hideTags: 'hideTags',
  },
} as const satisfies MonetateExperiment;

export const SHOW_MARKETING_BADGES = {
  experiment: 'showMarketingBadges',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showBadges: 'showBadges',
  },
} as const satisfies MonetateExperiment;

export const POD_WAS_PRICE = {
  experiment: 'podWasPrice',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showStrikethrough: 'showStrikethrough',
  },
} as const satisfies MonetateExperiment;

export const TROLLEY_PROMO_BADGE = {
  experiment: 'trolleyPromoBadgeV2',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayPromoBadgingV2: 'displayPromoBadgingV2',
  },
} as const satisfies MonetateExperiment;

export const EASTER_MODAL_EXPERIMENT = {
  experiment: 'easterModal',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayEasterModal: 'displayEasterModal',
  },
} as const satisfies MonetateExperiment;

export const HOME_PAGE_HEADER = {
  experiment: 'homePageHeader',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayGroceryHeader: 'displayGroceryHeader',
  },
} as const satisfies MonetateExperiment;

export const EOS_MULTIPLE_IMAGERY = {
  experiment: 'EoS_multipleImages',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    variantA: 'variantA',
  },
} as const satisfies MonetateExperiment;

export const EOS_MULTIPLE_IMAGERY_100 = {
  experiment: 'EoS_multipleImages_100',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    variantA: 'variantA',
  },
} as const satisfies MonetateExperiment;

export const EOS_HERO_IMAGERY = {
  experiment: 'EoS_heroImages',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    variantB: 'variantB',
  },
} as const satisfies MonetateExperiment;

export const BUILD_FOR_YOU_PAGE = {
  experiment: 'build_forYouPage',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showPage: 'showPage',
  },
} as const satisfies MonetateExperiment;

export const TROLLEY_RECOMMENDATIONS = {
  experiment: 'trolley_recommendations',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    displayRecommendations: 'displayRecommendations',
  },
} as const satisfies MonetateExperiment;

export const EOS_CHRISTMAS_HUB = {
  experiment: 'EoS_ChristmasHub',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showHub: 'showHub',
  },
} as const satisfies MonetateExperiment;

export const INSTORE_FAVOURITES = {
  experiment: 'build_instoreFavourites',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showInstoreFavourites: 'showInstoreFavourites',
  },
} as const satisfies MonetateExperiment;

export const SLOT_NEW_BOOKING_EXPERIENCE = {
  experiment: 'slots_newBookingExperience',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    show: 'show',
  },
} as const satisfies MonetateExperiment;

export const SLOTS_SERVICE_SELECTION_PAGE = {
  experiment: 'slots_serviceSelectionPage',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    skip: 'skip',
  },
} as const satisfies MonetateExperiment;

export const FAVOURITES_MORE_FROM_THIS_BRAND = {
  experiment: 'build_favouritesMoreFromThisBrand',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showMoreFromThisBrand: 'showMoreFromThisBrand',
  },
} as const satisfies MonetateExperiment;

export const EOS_MOBILE_BURGER_MENU_LOADING_STATE = {
  experiment: 'EoS_mobileBurgerMenuLoadingState',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    toggleBurgerMenuLoadingState: 'toggleBurgerMenuLoadingState',
  },
} as const satisfies MonetateExperiment;

export const FAVOURITES_STARTER_BASKET = {
  experiment: 'build_favouritesStarterBasket',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showStarterBasket: 'showStarterBasket',
  },
} as const satisfies MonetateExperiment;

export const FAVOURITES_SORTBY_SELECTABLE_CHIPS = {
  experiment: 'build_favouritesSortbySelectableChips',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showSelectableChips: 'showSelectableChips',
  },
} as const satisfies MonetateExperiment;

export const AEM_HOME_PAGE_PRELOAD_IMAGES = {
  experiment: 'aem_homePagePreloadImages',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    preload: 'preload',
  },
} as const satisfies MonetateExperiment;

export const CONTENT_VISIBILITY_FOOTER = {
  experiment: 'build_contentVisibilityFooter',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    lazyLoadFooter: 'lazyLoadFooter',
  },
} as const satisfies MonetateExperiment;

export const EOS_GLP_NAV = {
  experiment: 'EoS_glpNavigation',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    showMenu: 'showMenu',
  },
} as const satisfies MonetateExperiment;

export const AEM_CONTENT_IMAGE_QUALITY = {
  experiment: 'aem_contentImageQuality',
  variant: {
    default: MONETATE_DEFAULT_VARIANT,
    quality: 'quality',
  },
} as const satisfies MonetateExperiment;

// Register here the experiments
const allExperimentsList: [string, MonetateExperiment][] = [
  [URGENCY_BANNER.experiment, URGENCY_BANNER],
  [NAV_LINKS_TRENDING.experiment, NAV_LINKS_TRENDING],
  [SHOW_PRODUCT_TAGS.experiment, SHOW_PRODUCT_TAGS],
  [SHOW_MARKETING_BADGES.experiment, SHOW_MARKETING_BADGES],
  [POD_SLIDEDOWN_ADVERT_RECIPES.experiment, POD_SLIDEDOWN_ADVERT_RECIPES],
  [POD_SLIDEDOWN_ADVERT_RELATED.experiment, POD_SLIDEDOWN_ADVERT_RELATED],
  [POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH.experiment, POD_SLIDEDOWN_ADVERT_GOES_WELL_WITH],
  [POD_SLIDEDOWN_MULTIBUY_NUDGE.experiment, POD_SLIDEDOWN_MULTIBUY_NUDGE],
  [POD_WAS_PRICE.experiment, POD_WAS_PRICE],
  [TROLLEY_PROMO_BADGE.experiment, TROLLEY_PROMO_BADGE],
  [EASTER_MODAL_EXPERIMENT.experiment, EASTER_MODAL_EXPERIMENT],
  [HOME_PAGE_HEADER.experiment, HOME_PAGE_HEADER],
  [EOS_MULTIPLE_IMAGERY.experiment, EOS_MULTIPLE_IMAGERY],
  [EOS_MULTIPLE_IMAGERY_100.experiment, EOS_MULTIPLE_IMAGERY_100],
  [EOS_HERO_IMAGERY.experiment, EOS_HERO_IMAGERY],
  [BUILD_FOR_YOU_PAGE.experiment, BUILD_FOR_YOU_PAGE],
  [TROLLEY_RECOMMENDATIONS.experiment, TROLLEY_RECOMMENDATIONS],
  [EOS_CHRISTMAS_HUB.experiment, EOS_CHRISTMAS_HUB],
  [INSTORE_FAVOURITES.experiment, INSTORE_FAVOURITES],
  [LOYALTY_SHOW_MIGRATION_COMPETITIONS_PAGE.experiment, LOYALTY_SHOW_MIGRATION_COMPETITIONS_PAGE],
  [LOYALTY_SHOW_MIGRATION_BENEFITS_PAGE.experiment, LOYALTY_SHOW_MIGRATION_BENEFITS_PAGE],
  [
    LOYALTY_SHOW_MIGRATION_CARDS_AND_DETAILS_PAGE.experiment,
    LOYALTY_SHOW_MIGRATION_CARDS_AND_DETAILS_PAGE,
  ],
  [LOYALTY_REDIRECT_HUB_TO_VOUCHERS_PAGE.experiment, LOYALTY_REDIRECT_HUB_TO_VOUCHERS_PAGE],
  [SLOT_NEW_BOOKING_EXPERIENCE.experiment, SLOT_NEW_BOOKING_EXPERIENCE],
  [SLOTS_SERVICE_SELECTION_PAGE.experiment, SLOTS_SERVICE_SELECTION_PAGE],
  [FAVOURITES_MORE_FROM_THIS_BRAND.experiment, FAVOURITES_MORE_FROM_THIS_BRAND],
  [EOS_MOBILE_BURGER_MENU_LOADING_STATE.experiment, EOS_MOBILE_BURGER_MENU_LOADING_STATE],
  [FAVOURITES_STARTER_BASKET.experiment, FAVOURITES_STARTER_BASKET],
  [FAVOURITES_SORTBY_SELECTABLE_CHIPS.experiment, FAVOURITES_SORTBY_SELECTABLE_CHIPS],
  [AEM_HOME_PAGE_PRELOAD_IMAGES.experiment, AEM_HOME_PAGE_PRELOAD_IMAGES],
  [CONTENT_VISIBILITY_FOOTER.experiment, CONTENT_VISIBILITY_FOOTER],
  [EOS_GLP_NAV.experiment, EOS_GLP_NAV],
  [AEM_CONTENT_IMAGE_QUALITY.experiment, AEM_CONTENT_IMAGE_QUALITY],
] as const;

const allExperiments = new Map<string, MonetateExperiment>(allExperimentsList);

const monetateExperimentIds = [...allExperiments.keys()];

export const getMonetateExperimentIds = () => monetateExperimentIds;
export const getMonetateExperimentById = (id: string) => allExperiments.get(id);
export const matchDecisionByExperiment = (id: string, variantDecision: string) =>
  id
    ? getMonetateExperimentById(id)?.variant[variantDecision] || MONETATE_DEFAULT_VARIANT
    : MONETATE_DEFAULT_VARIANT;

export type Experiments = EntriesToObject<typeof allExperimentsList>;

export type GetVariantsByExperiment<T extends keyof Experiments> =
  Experiments[T]['variant'][keyof Experiments[T]['variant']];
