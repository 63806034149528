import { apiCallback } from 'api';
import trolleyConflictFragment from '../trolleyItems/trolley-conflict-fragment.graphql';
import trolleyItemFragment from '../trolleyItems/trolley-item-fragment.graphql';
import trolleyProductFragment from '../trolleyItems/trolley-product-fragment.graphql';
import trolleyTotalsFragment from '../trolleyItems/trolley-totals-fragment.graphql';
import updateTrolleyItemsMutation from './update-trolley-items.graphql';
import { TrolleyItemInput } from './types';

const graphDefinition = {
  graph: true,
  query: (): string =>
    `${trolleyConflictFragment}${trolleyItemFragment}${trolleyProductFragment}${trolleyTotalsFragment}${updateTrolleyItemsMutation}`,
  variables: ({
    merge,
    orderId,
    body,
  }: {
    merge: boolean;
    orderId: string;
    body: TrolleyItemInput[];
  }): {
    merge: boolean;
    orderId: string;
    trolleyItems: TrolleyItemInput[];
  } => ({
    orderId,
    trolleyItems: body,
    merge,
  }),
  queryString: { tag: 'update-trolley-items' },
};

export default {
  patch: apiCallback(graphDefinition, 'post'),
};
