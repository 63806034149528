import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { compose } from 'redux';

// Ensure we always have a 'productsInResultset' field and 'componentsAndProducts' collection
// containing 'searchProduct' or 'aemComponent' items.
const regulariseResponse = data => {
  if ('products' in data) {
    return {
      ...omit(data, 'products'),
      componentsAndProducts: (data.products || []).map(product => ({ searchProduct: product })),
      productsInResultset: data.products.length,
    };
  }
  return {
    ...data,
    componentsAndProducts: data.componentsAndProducts || [],
  };
};

// Extract fields that have no business being in product entities and hoist them into keys in
// the parent object that are siblings to 'searchProduct'.  This is needed so that products can
// be normalised without responsive duplicates overwriting predecessors in the entities store.
const promoteEntityFields = ['cqResponsive', 'sponsored'];

const getEntityName = parent => ['searchProduct', 'aemComponent'].find(key => key in parent);

const purifyGridItem = parent => {
  const entityName = getEntityName(parent);
  const entity = parent[entityName];
  switch (entityName) {
    case 'searchProduct':
      return {
        ...parent,
        ...(entity && { [entityName]: omit(entity, promoteEntityFields) }),
        ...(entity && pick(entity, promoteEntityFields)),
      };
    case 'aemComponent':
      // aemComponent grid item wrappers will also be decorated so we can make
      // ProductList code consistent, but retain their nested fields until we
      // have time to update component code accordingly
      return {
        ...parent,
        ...(entity && pick(entity, promoteEntityFields)),
      };
    default:
      return parent;
  }
};

const purifyGridItems = data => {
  const conflicts = data.componentsAndProducts
    .flatMap(({ searchProduct }) =>
      searchProduct?.conflicts?.map(conflict => ({ ...conflict, productId: conflict.lineNumber })),
    )
    .filter(e => e !== undefined);

  return {
    ...data,
    componentsAndProducts: data.componentsAndProducts.map(purifyGridItem),
    ...(conflicts.length && { conflicts }),
  };
};

export default compose(purifyGridItems, regulariseResponse);
