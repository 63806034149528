import type { ApiCallResponse } from 'api/types';
import banner from 'api/definitions/adtech';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { type BannerResponse } from 'api/definitions/adtech/types';
import { ADTECH_BANNERS } from './types';

export const getBanner =
  (categories: string[]): WtrThunkAction<Promise<void | ApiCallResponse<BannerResponse>>> =>
  (dispatch, getState) =>
    dispatch({
      types: ADTECH_BANNERS.triplet,
      apiCall: banner.post({
        body: {
          type: 'mega-menu',
          categories,
          style: 'traffic_driver',
          trolleyId: getCustomerOrderId(getState()),
        },
        iHandleStatusCodes: true,
      }),
    });
