import React, { useCallback, MutableRefObject } from 'react';
import cx from 'classnames';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton/LinkAsButton';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import { requestIdleCallback } from 'utils/settimeout-wrapper';
import { usePodObserver } from 'hooks/use-pod-observer';
import { type Banner } from 'api/definitions/adtech/types';
import { EventType, sendAdTechEvent } from 'analytics/gtmServerFunctions';
import styles from './styles.scss';

export const AdtechCard = ({
  banner: { id, textArea, image, tracking },
  className,
}: { banner: Banner } & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>) => {
  const observerRef: MutableRefObject<HTMLDivElement> = usePodObserver(
    useCallback(() => {
      requestIdleCallback(() =>
        sendAdTechEvent({
          eventType: EventType.IMPRESSION,
          sponsorshipId: id,
          trackingUrls: tracking.impressionUrls,
          vendor: tracking.vendor,
        }),
      );
    }, [id, tracking.impressionUrls, tracking.vendor]),
  ) as unknown as MutableRefObject<HTMLDivElement>;

  const callbackRef = useCallback(
    (node: HTMLDivElement) => {
      observerRef.current = node;
    },
    [observerRef],
  );

  return (
    <div ref={callbackRef} className={cx(styles.ad, className)}>
      <img src={image.src} alt="" />
      <h3>
        <span className={styles.promo}>{textArea.offerHeading}</span> {textArea.heading}
      </h3>
      <Typography>{textArea.subheading}</Typography>
      <LinkAsButton
        href={textArea.link.url}
        width="full"
        aria-label={textArea.link.altText}
        onClick={() => {
          sendAdTechEvent({
            eventType: EventType.CLICK,
            sponsorshipId: id,
            trackingUrls: tracking.clickUrls,
            vendor: tracking.vendor,
          });
        }}
      >
        {textArea.link.text}
      </LinkAsButton>
    </div>
  );
};
