import React from 'react';
import { TradingCell } from '@johnlewispartnership/wtr-content-component-library';

import { AEM_CONTENT_IMAGE_QUALITY } from 'constants/monetateExperiments';
import { useDecisionById } from 'components/Experiment/useDecisionById';
import { usePromoImpression } from 'hooks/use-promo-impression';
import { EventType, sendAdTechEvent } from 'analytics/gtmServerFunctions';

const TradingCellWrapper = (props: any) => {
  const { componentId, analytics, position, products, isCitrusAd } = props;

  const { variant, payload } = useDecisionById<object>(AEM_CONTENT_IMAGE_QUALITY.experiment);
  const observerRef = usePromoImpression({ componentId, analytics, position, products });

  const promoClickHandler = () => {
    if (isCitrusAd) {
      sendAdTechEvent({ eventType: EventType.CLICK, sponsorshipId: componentId });
    }
  };

  if (variant !== 'default') {
    const tradingCellProps = {
      ...props,
      ...payload,
    };

    return <TradingCell ref={observerRef} {...tradingCellProps} onPromoClick={promoClickHandler} />;
  }

  return <TradingCell ref={observerRef} {...props} onPromoClick={promoClickHandler} />;
};

export default TradingCellWrapper;
