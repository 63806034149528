import { apiCallback } from 'api';
import { DocumentNode } from 'graphql';
import trolleyConflictFragment from './trolley-conflict-fragment.graphql';
import trolleyItemFragment from './trolley-item-fragment.graphql';
import trolleyProductFragment from './trolley-product-fragment.graphql';
import trolleyTotalsFragment from './trolley-totals-fragment.graphql';
import addItemToTrolley from './add-item-to-trolley.graphql';
import emptyTrolleyMutation from './empty-trolley.graphql';
import replaceTrolleyItemMutation from './replace-trolley-item.graphql';
import updateTrolleyItemMutation from './update-trolley-item.graphql';
import updateTrolleyItemsMutation from './update-trolley-items.graphql';
import { TrolleyItemInput } from './types';

const graphDef = (graphQuery: DocumentNode, type: string) => {
  let queryString = null;
  let query = () =>
    `${trolleyConflictFragment}${trolleyItemFragment}${trolleyProductFragment}${trolleyTotalsFragment}${graphQuery}`;
  let variables = ({
    orderId,
    body: {
      canSubstitute,
      lineNumber,
      noteToShopper,
      personalisedMessage,
      productId,
      quantity,
      reservedQuantity,
      trolleyItemId,
    },
  }: {
    orderId: string;
    body: TrolleyItemInput;
  }): { orderId: string; trolleyItem?: TrolleyItemInput } => ({
    orderId,
    trolleyItem: {
      canSubstitute,
      lineNumber,
      noteToShopper,
      personalisedMessage,
      productId,
      reservedQuantity,
      quantity,
      trolleyItemId,
    },
  });
  if (type === 'updateTrolleyItems') {
    variables = ({ orderId, body }) => ({
      orderId,
      trolleyItems: body,
    });
    query = () => `${graphQuery}`;
    queryString = { tag: 'update-trolley-items' };
  }
  if (type === 'emptyTrolley') {
    variables = ({ orderId }) => ({
      orderId,
    });
    queryString = { tag: 'empty-trolley' };
  }
  if (type === 'updateTrolleyItem') {
    query = () =>
      `${trolleyConflictFragment}${trolleyItemFragment}${trolleyTotalsFragment}${graphQuery}`;
    queryString = { tag: 'update-trolley-item' };
  }
  if (type === 'addItemToTrolley') {
    query = () =>
      `${trolleyConflictFragment}${trolleyItemFragment}${trolleyTotalsFragment}${graphQuery}`;
    variables = ({
      orderId,
      body: {
        canSubstitute,
        lineNumber,
        noteToShopper,
        personalisedMessage,
        productId,
        quantity,
        trolleyItemId,
      },
    }) => ({
      orderId,
      trolleyItem: {
        canSubstitute,
        lineNumber,
        noteToShopper,
        personalisedMessage,
        productId,
        quantity,
        trolleyItemId,
      },
    });
    queryString = { tag: 'add-item' };
  }
  if (type === 'undoRemoveTrolleyItem') {
    query = () => `${graphQuery}`;
    queryString = { tag: 'replace-trolley-item' };
    variables = ({
      orderId,
      body: {
        canSubstitute,
        lineNumber,
        noteToShopper,
        personalisedMessage,
        quantity,
        trolleyItemId,
      },
    }) => ({
      orderId,
      trolleyItem: {
        canSubstitute,
        lineNumber,
        noteToShopper,
        personalisedMessage,
        quantity,
        trolleyItemId,
      },
    });
  }

  return { graph: true, query, variables, queryString };
};

export default {
  emptyTrolley: apiCallback(graphDef(emptyTrolleyMutation, 'emptyTrolley'), 'post'),
  bulkUpdate: apiCallback(graphDef(updateTrolleyItemsMutation, 'updateTrolleyItems'), 'post'),
  addItem: apiCallback(graphDef(addItemToTrolley, 'addItemToTrolley'), 'post'),
  updateItem: apiCallback(graphDef(updateTrolleyItemMutation, 'updateTrolleyItem'), 'post'),
  replaceItem: apiCallback(graphDef(replaceTrolleyItemMutation, 'undoRemoveTrolleyItem'), 'post'),
};
